// src/components/ContactPage.js
import React from 'react'

const BreadCrumb = () => {
  return (
    <section className="page-title">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <h2>Contact</h2>
            <ol className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Contact</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BreadCrumb
