// src/components/ContactPage.js
import React from 'react'

const PreLoader = () => {
  return (
    <div className="preloader">
      <div className="preloader-inner">
        <img src="assets/images/preloader.gif" alt="Loading" />
      </div>
    </div>
  )
}

export default PreLoader
