// src/components/ContactPage.js
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-md-3 col-sm-6">
              <div className="widget about-widget">
                <div className="footer-logo">
                  <img src="assets/images/logo.png" />
                </div>
                <ul className="contact-info">
                  <li>
                    <i className="fa fa-home"></i> No:19/9, Sadiq Basha Nagar,
                    1st Street, Alwarthirunagar, Chennai - 600087
                  </li>
                  <li>
                    <i className="fa fa-phone"></i> +917299044635
                  </li>
                  <li>
                    <i className="fa fa-home"></i> Working Hours: <br />
                    Mon-Fri (9 am - 8 pm)
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-md-3 col-sm-6">
              <div className="widget service-links-widget">
                <h3>Services</h3>
                <ul>
                  <li>
                    <Link to="/about">Modular Kitchen</Link>
                  </li>
                  <li>
                    <Link to="/about">Digital Plotting</Link>
                  </li>
                  <li>
                    <Link to="/about">2d Drafting</Link>
                  </li>
                  <li>
                    <Link to="/about">Printing and Plotting</Link>
                  </li>
                  <li>
                    <Link to="/about">Blue Print</Link>
                  </li>
                  <li>
                    <Link to="/about">Plan Approval</Link>
                  </li>
                  <li>
                    <Link to="/about">Structural Designing Services</Link>
                  </li>
                  <li>
                    <Link to="/about">Web Development</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-md-3 col-sm-6">
              <div className="widget quick-links-widget">
                <h3>Navigation</h3>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="#">Blogs</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">Gallery</a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-md-3 col-sm-6">
              <div className="widget twitter-feed-widget">
                <h3>Twitter Feed</h3>
                <ul>
                  <li>
                    <div className="text">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit. Ed quia con sequuntur magni dolores.
                      </p>
                    </div>
                    <div className="info-box">
                      <i className="fa fa-twitter"></i>
                      <strong>
                        <a href="#">@Mark Wahlberg</a>
                      </strong>
                    </div>
                  </li>
                  <li>
                    <div className="text">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit. Ed quia con sequuntur magni dolores.
                      </p>
                    </div>
                    <div className="info-box">
                      <i className="fa fa-twitter"></i>
                      <strong>
                        <a href="#">@Mark Wahlberg</a>
                      </strong>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      {/* end upper-footer  */}
      <div className="copyright-info">
        <div className="container">
          <p>
            2017 &copy; All Rights Reserved by{' '}
            <a href="http://themeforest.net/user/themexriver">Themexriver</a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
