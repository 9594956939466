// src/components/Home.js
import React from 'react'

const Home = () => {
  return (
    <div className="home-pg">
      {/* start offer */}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-md-4">
              <div className="section-title">
                <h2>What we offer</h2>
              </div>
              <div className="offer-text">
                <p>
                  Dynamic and cost-effective services: CAD design services for
                  2D drafting, CAD drafting services, Animation & Walkthrough’s,
                  FMB drawings
                </p>
                <p>
                  building plan services tailored to all engineering industries.
                </p>
                <a href="#" className="theme-btn read-more">
                  Read More
                </a>
              </div>
            </div>
            <div className="col col-md-8">
              <div className="offer-grids">
                <div className="grid">
                  <div className="details">
                    <div className="icon">
                      <i className="fi flaticon-construction"></i>
                    </div>
                    <h3>Advanced Technology</h3>
                    <p>
                      Sed quia non numquam eius modi tempora incidunt ut labore
                      et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <a href="#" className="offer-details">
                      Details <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
                <div className="grid">
                  <div className="details">
                    <div className="icon">
                      <i className="fi flaticon-people"></i>
                    </div>
                    <h3>Expert Engineers</h3>
                    <p>
                      Sed quia non numquam eius modi tempora incidunt ut labore
                      et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <a href="#" className="offer-details">
                      Details <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
                <div className="grid">
                  <div className="details">
                    <div className="icon">
                      <i className="fi flaticon-support"></i>
                    </div>
                    <h3>Customer Support</h3>
                    <p>
                      Sed quia non numquam eius modi tempora incidunt ut labore
                      et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <a href="#" className="offer-details">
                      Details <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
                <div className="grid">
                  <div className="details">
                    <div className="icon">
                      <i className="fi flaticon-time-passing"></i>
                    </div>
                    <h3>Delivery On time</h3>
                    <p>
                      Sed quia non numquam eius modi tempora incidunt ut labore
                      et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <a href="#" className="offer-details">
                      Details <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          {/* end row */}
        </div>{' '}
        {/* end container */}
      </section>
      {/* end offer */}

      {/* start about-us-faq */}
      <section className="section-padding about-us-faq">
        <div className="container">
          <div className="row">
            <div className="col col-lg-5">
              <div className="about-us-section">
                <div className="section-title-s3">
                  <span>About us</span>
                  <h2>
                    Get full range of premium Industrial services for your
                    business
                  </h2>
                </div>
                <div className="details">
                  <p>
                    To provide cost-effective CAD services with precision and
                    compliance with the latest codes. We strive to build strong,
                    long-term business relationships through quality services,
                    including FMB drawings and building plan design.
                  </p>
                  <p>
                    To become a global engineering company delivering
                    cost-effective and innovative solutions for CAD design
                    services, FMB drawings, and other engineering needs.
                  </p>
                  <div className="btns">
                    <a href="#" className="theme-btn-s3">
                      Read More
                    </a>
                    <a href="#" className="theme-btn-s4">
                      Company History
                    </a>
                  </div>
                </div>
                <div className="social">
                  <p>Get Connected With Us:</p>
                  <ul className="social-links">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-6 col-lg-offset-1">
              <div className="faq-section">
                <div className="section-title-s3">
                  <span>FAQ</span>
                  <h2>
                    All these years, our different services have delivered long
                    lasting innovation
                  </h2>
                </div>
                <div className="details">
                  <p>
                    AMS Designing Solutions is an India-based company
                    specializing in Engineering and CAD services, including 3D
                    CAD design services, CAD drafting services, and FMB drawing
                    solutions for accurate land and property measurements.
                  </p>
                  <div
                    className="panel-group faq-accordion theme-accordion-s1"
                    id="accordion"
                  >
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                          aria-expanded="true"
                        >
                          Modular Kitchen Design Services
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse in"
                      >
                        <div className="panel-body">
                          <p>
                            Tailored modular kitchen designs for modern and
                            traditional homes
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseTwo"
                        >
                          Digital Plotting and Printing Services
                        </a>
                      </div>
                      <div id="collapseTwo" className="panel-collapse collapse">
                        <div className="panel-body">
                          <p>
                            High-quality digital plotting and CAD drafting
                            solutions for engineering projects
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseThree"
                        >
                          2D Drafting and Detailing Services
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body">
                          <p>
                            Precision-driven 2D drafting services for various
                            industries.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          {/* end row */}
        </div>{' '}
        {/* end container */}
        <div className="backhoe-loader">
          <img src="assets/images/backhoe-loader.png" alt />
        </div>
      </section>
      {/* end start about-us-faq */}

      {/* start contact-section */}
      <section
        className="contact-section section-padding parallax"
        data-bg-image="assets/images/contact-section-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col col-md-4 col-md-offset-1 col-md-5">
              <div className="contact-section-contact-box">
                <div className="section-title-white">
                  <h2>Contact</h2>
                </div>
                <div className="details">
                  <p>
                    For any kind of query, contact us with the details below.
                  </p>
                  <ul>
                    <li>
                      <i className="fa fa-phone"></i> +123 (569) 254 78
                    </li>
                    <li>
                      <i className="fa fa-envelope"></i> info@industrial.com
                    </li>
                    <li>
                      <i className="fa fa-home"></i> #59, East Madison Ave,
                      Melbourne Australia
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col col-lg-6 col-lg-offset-1 col-md-7">
              <div className="section-title-white">
                <h2>Request a quote</h2>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do
                eiusmod tempor incidi dunt ut labore et dolore magna aliqua.
              </p>

              <div className="contact-form-s1 form">
                <form
                  method="post"
                  id="contact-form"
                  className="wpcf7-form contact-validation-active"
                >
                  <div>
                    <label htmlFor="name">Full Name</label>
                    <input type="text" id="name" name="name" />
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" />
                  </div>
                  <div>
                    <label htmlFor="phone">Phone Number</label>
                    <input type="text" id="phone" name="phone" />
                  </div>
                  <div>
                    <label>Business Type</label>
                    <select name="select">
                      <option selected disabled>
                        {' '}
                        -- Select One --{' '}
                      </option>
                      <option value="Select One">Select One</option>
                      <option value="Select Two">Select Two</option>
                      <option value="Select Three">Select Three</option>
                    </select>
                  </div>
                  <div className="submit-btn-wrap">
                    <input
                      value="Submit"
                      className="theme-btn wpcf7-submit"
                      type="submit"
                    />
                    <div id="loader">
                      <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                    </div>
                  </div>
                  <div className="error-handling-messages">
                    <div id="success">Thank you</div>
                    <div id="error">
                      {' '}
                      Error occurred while sending email. Please try again
                      later.{' '}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>{' '}
          {/* end row */}
        </div>{' '}
        {/* end container */}
        <div className="contact-women wow fadeInLeft">
          <img src="assets/images/contact-women.png" alt />
        </div>
      </section>
      {/* end contact-section */}

      {/* news-section */}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-4">
              <div className="section-title">
                <h2>Blogs</h2>
              </div>
            </div>
            <div className="col col-lg-6 col-md-5">
              <p>
                Explore expert insights across various domains like Mechanical,
                IT, Commerce, and Economics, including CAD drafting, FMB
                drawings, and building plan services.
              </p>
            </div>
            <div className="col col-lg-3 col-md-3">
              <div className="all-news-link">
                <a href="#" className="theme-btn">
                  More News
                </a>
              </div>
            </div>
          </div>{' '}
          {/* end row */}
          <div className="row">
            <div className="col col-xs-12">
              <div className="news-grids">
                <div className="grid">
                  <div className="entry-media">
                    <img src="assets/images/blog/img-1.jpg" alt />
                  </div>
                  <div className="entry-details">
                    <div className="entry-meta">
                      <ul>
                        <li>
                          <i className="fa fa-clock-o"></i>Nov 25
                        </li>
                        <li>
                          <i className="fa fa-comments"></i>
                          <a href="#">2 Comments</a>
                        </li>
                      </ul>
                    </div>
                    <div className="entry-body">
                      <h3>
                        <a href="#">Wardrobe Designs</a>
                      </h3>
                      <p>
                        Designs tailored for traditional and luxurious Indian
                        homes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="entry-media">
                    <img src="assets/images/blog/img-2.jpg" alt />
                  </div>
                  <div className="entry-details">
                    <div className="entry-meta">
                      <ul>
                        <li>
                          <i className="fa fa-clock-o"></i>Nov 25
                        </li>
                        <li>
                          <i className="fa fa-comments"></i>
                          <a href="#">2 Comments</a>
                        </li>
                      </ul>
                    </div>
                    <div className="entry-body">
                      <h3>
                        <a href="#">
                          Excellent Blueprint Services in Alwarthirunagar
                          Chennai
                        </a>
                      </h3>
                      <p>
                        Blueprints form the foundation of great structures.
                        Discover our cost-effective blueprint services in
                        Chennai for engineering projects.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="entry-media">
                    <img src="assets/images/blog/img-3.jpg" alt />
                  </div>
                  <div className="entry-details">
                    <div className="entry-meta">
                      <ul>
                        <li>
                          <i className="fa fa-clock-o"></i>Nov 25
                        </li>
                        <li>
                          <i className="fa fa-comments"></i>
                          <a href="#">2 Comments</a>
                        </li>
                      </ul>
                    </div>
                    <div className="entry-body">
                      <h3>
                        <a href="#">
                          Structural Engineering Experts in Alwarthirunagar
                          Chennai
                        </a>
                      </h3>
                      <p>
                        Mastering modern building structures with innovative
                        structural CAD design services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        {/* end container */}
      </section>
    </div>
  )
}

export default Home
