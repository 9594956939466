// src/components/ContactPage.js
import React from 'react'
// import Header from './common/Header'
// import Footer from './common/Footer'
// import PreLoader from './common/PreLoader'
// import BreadCrumb from './common/BreadCrumb'

const Contact = () => {
  return (
    <div className="page-wrapper">
      {/* <PreLoader />
      <Header />
      <BreadCrumb /> */}
      <section className="contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-md-3">
              <div className="contact-info">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fa fa-phone"></i>
                    </div>
                    <p>
                      <span>Hotline</span> +1 478 (2492) 54{' '}
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <p>
                      <span>Email</span> support@industrial.com{' '}
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-clock-o"></i>
                    </div>
                    <p>
                      <span>Working Hours:</span> +1 478 (2492) 54{' '}
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-location-arrow"></i>
                    </div>
                    <p>
                      <span>Office</span> 1802 Stout Rd, Menomonie,WI 54751{' '}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-md-offset-1 col-md-8">
              <div className="location-map" id="map"></div>
            </div>
          </div>{' '}
          {/* end row  */}
          <div className="row">
            <div className="col col-xs-12">
              <form
                className="contact-form form contact-validation-active row"
                id="contact-form-s2"
              >
                <div className="col col-sm-6">
                  <label htmlFor="f-name">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="f-name"
                    name="f_name"
                  />
                </div>
                <div className="col col-sm-6">
                  <label htmlFor="l-name">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="l-name"
                    name="l_name"
                  />
                </div>
                <div className="col col-sm-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                  />
                </div>
                <div className="col col-sm-6">
                  <label htmlFor="phone">Phone No</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                  />
                </div>
                <div className="col col-sm-12">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="note"
                  ></textarea>
                </div>
                <div className="col col-sm-12 submit-btn">
                  <button className="theme-btn">Submit</button>
                  <div id="loader">
                    <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                  </div>
                </div>
                <div className="error-handling-messages">
                  <div id="success">Thank you</div>
                  <div id="error">
                    {' '}
                    Error occurred while sending email. Please try again later.{' '}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>{' '}
        {/* end container  */}
      </section>
      {/* <Footer /> */}
    </div>
  )
}

export default Contact
