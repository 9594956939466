import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import PreLoader from './components/common/PreLoader'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import BreadCrumb from './components/common/BreadCrumb'

function App1() {
  const [loading, setLoading] = useState(true)

  // Simulate loading delay for PreLoader
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000) // Adjust the delay as needed
    return () => clearTimeout(timer)
  }, [])

  return (
    <Router>
      <div className="app-wrapper">
        {/* Conditionally render PreLoader if loading is true */}
        {loading ? (
          <PreLoader />
        ) : (
          <>
            {/* Header and Breadcrumb are shown on every page */}
            <Header />
            <BreadCrumb />

            {/* Main content with Routes */}
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </main>

            {/* Footer shown on every page */}
            <Footer />
          </>
        )}
      </div>
    </Router>
  )
}

export default App1
