// src/components/ContactPage.js
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  // Toggle function to open/close the submenu
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen)
  }
  return (
    <header id="header" className="site-header header-style-5">
      <div className="topbar topbar-style-2">
        <div className="container">
          <div className="row">
            <div className="col col-sm-7">
              <div className="topbar-contact-info-wrapper">
                <div className="topbar-contact-info">
                  <div>
                    <i className="fa fa-location-arrow"></i>
                    <div className="details">
                      <p>5523 Research Park Drive, Suite 110</p>
                    </div>
                  </div>
                  <div>
                    <i className="fa fa-phone"></i>
                    <div className="details">
                      <p>+012 (3546) 547</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-sm-5">
              <div className="social">
                <span>Follow: </span>
                <ul className="social-links">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-rss"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>{' '}
        {/* end container  */}
      </div>{' '}
      {/* end topbar  */}
      <div className="lower-topbar">
        <div className="container">
          <div className="row">
            <div className="col col-sm-3">
              <div className="site-logo">
                <a href="index.html">
                  <img src="assets/images/logo-2.png" alt="Logo" />
                </a>
              </div>
            </div>
            <div className="col col-sm-9">
              <div className="awards">
                <div>
                  <div className="icon">
                    <i className="fi flaticon-title"></i>
                  </div>
                  <div className="award-info">
                    <h4>Global Certified</h4>
                    <p>ISO 9001:2016</p>
                  </div>
                </div>
                <div>
                  <div className="icon">
                    <i className="fi flaticon-medal"></i>
                  </div>
                  <div className="award-info">
                    <h4>Winner</h4>
                    <p>Construction award 2016</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end lower-topbar  */}
      <nav className="navigation navbar navbar-default">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="open-btn" onClick={toggleMenu}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div
            id="navbar"
            className={`navbar-collapse navigation-holder ${
              isMenuOpen ? 'slideInn' : 'collapse'
            }`}
          >
            <button className="close-navbar" onClick={toggleMenu}>
              <i className="fa fa-close"></i>
            </button>
            <ul className="nav navbar-nav">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li class="menu-item-has-children">
                <a onClick={toggleSubMenu} href="#">
                  Services
                </a>
                {isSubMenuOpen && (
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">Modular Kitchen</Link>
                    </li>
                    <li>
                      <Link to="/about">Digital Plotting</Link>
                    </li>
                    <li>
                      <Link to="/about">2d Drafting</Link>
                    </li>
                    <li>
                      <Link to="/about">Printing and Plotting</Link>
                    </li>
                    <li>
                      <Link to="/about">Blue Print</Link>
                    </li>
                    <li>
                      <Link to="/about">Plan Approval</Link>
                    </li>
                    <li>
                      <Link to="/about">Structural Designing Services</Link>
                    </li>
                    <li>
                      <Link to="/about">Web Development</Link>
                    </li>
                  </ul>
                )}
              </li>

              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          {/* end of nav-collapse  */}

          <div className="cart-contact">
            <div className="mini-cart">
              <button className="cart-toggle-btn">
                {' '}
                <i className="fi flaticon-shopping-cart"></i> Cart (2){' '}
              </button>

              <div className="top-cart-content">
                <div className="top-cart-title">
                  <p>Shopping Cart</p>
                </div>
                <div className="top-cart-items">
                  <div className="top-cart-item clearfix">
                    <div className="top-cart-item-image">
                      <a href="#">
                        <img
                          src="assets/images/shop/small/1.jpg"
                          alt="Blue Round-Neck Tshirt"
                        />
                      </a>
                    </div>
                    <div className="top-cart-item-des">
                      <a href="#">Blue Round-Neck Tshirt</a>
                      <span className="top-cart-item-price">$19.99</span>
                      <span className="top-cart-item-quantity">x 2</span>
                    </div>
                  </div>
                  <div className="top-cart-item clearfix">
                    <div className="top-cart-item-image">
                      <a href="#">
                        <img
                          src="assets/images/shop/small/6.jpg"
                          alt="Light Blue Denim Dress"
                        />
                      </a>
                    </div>
                    <div className="top-cart-item-des">
                      <a href="#">Light Blue Denim Dress</a>
                      <span className="top-cart-item-price">$24.99</span>
                      <span className="top-cart-item-quantity">x 3</span>
                    </div>
                  </div>
                </div>
                <div className="top-cart-action clearfix">
                  <span className="fleft top-checkout-price">$114.95</span>
                  <a href="" className="theme-btn">
                    View Cart
                  </a>
                </div>
              </div>
            </div>
          </div>

          <a href="#" className="theme-btn-s2 request-quote">
            Request Quote
          </a>
        </div>
        {/* end of container  */}
      </nav>{' '}
      {/* end navigation  */}
    </header>
  )
}

export default Header
