// src/components/About.js
import React from 'react'

const About = () => {
  return (
    <section className="about-pg-section section-padding">
      <section className="section-padding offer-section">
        <div className="container">
          <div className="row">
            <div className="col col-md-5">
              <div className="section-title-s3">
                <h2>
                  Get full range of premium Industrial services for your
                  business
                </h2>
              </div>
              <div className="offer-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisc ing elit, sed
                  do eiusmod tempor inci didunt ut labore et dolore magna.
                </p>
                <p>
                  Ut enim ad minima veniam, quis nostrum exercitationem ullam
                  corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                  consequatur
                </p>
                <a href="#" className="theme-btn read-more">
                  Read More
                </a>
                <a href="#" className="theme-btn-s2 read-more">
                  Company history
                </a>
              </div>
            </div>
            <div className="col col-md-7">
              <div className="offer-pic">
                <img src="assets/images/offer-pic.jpg" />
              </div>
            </div>
          </div>{' '}
          {/* end row */}
        </div>{' '}
        {/* end container */}
      </section>
      {/* end offer */}

      {/* start cta */}
      <section
        className="cta section-padding parallax"
        data-bg-image="assets/images/cta-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <h2>
                Our one and only priority is the{' '}
                <span>customer satisfaction</span>
              </h2>
              <a href="#" className="theme-btn-s5">
                Get a quote
              </a>
            </div>
          </div>
        </div>{' '}
        {/* end container */}
      </section>
      {/* end cta */}

      {/* start features */}
      <section className="features section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-md-3">
              <div className="features-title">
                <h2>Why we are best</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed
                  do eiusmod tempor.
                </p>
              </div>
            </div>
            <div className="col col-md-3 col-sm-4">
              <div className="feature-grid">
                <div className="icon">
                  <i className="fi flaticon-people"></i>
                </div>
                <div className="details">
                  <h3>Expert Engineers</h3>
                  <p>
                    Sed quia non numquam eius modi tempo ra incidunt ut labore
                    et dolore magnam aliq uam quaera.
                  </p>
                  <a href="#" className="more">
                    Details <i className="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-md-3 col-sm-4">
              <div className="feature-grid">
                <div className="icon">
                  <i className="fi flaticon-support"></i>
                </div>
                <div className="details">
                  <h3>Customer Support</h3>
                  <p>
                    Sed quia non numquam eius modi tempo ra incidunt ut labore
                    et dolore magnam aliq uam quaera.
                  </p>
                  <a href="#" className="more">
                    Details <i className="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-md-3 col-sm-4">
              <div className="feature-grid">
                <div className="icon">
                  <i className="fi flaticon-time-passing"></i>
                </div>
                <div className="details">
                  <h3>Delivery On time</h3>
                  <p>
                    Sed quia non numquam eius modi tempo ra incidunt ut labore
                    et dolore magnam aliq uam quaera.
                  </p>
                  <a href="#" className="more">
                    Details <i className="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        {/* end container */}
      </section>
      {/* end features */}
    </section>
  )
}

export default About
